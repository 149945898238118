<template>
  <el-dialog :model-value="showDialog" :show-close="true" :close-on-click-modal="false" :close-on-press-escape="false"
    :append-to-body="true" center width="1000px" @close="closeDialog">

    <template #title>
      <span>库存使用详情</span>
    </template>

    <div class="filter-box">
      <div class="right-box">
        <el-button type="primary" size="small" icon="el-icon-refresh" @click="handleRefresh">刷新券状态</el-button>
        <el-button type="primary" size="small" icon="el-icon-download" @click="handleExport">导出 Excel</el-button>
      </div>
    </div>

    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="code" label="券码" />
        <el-table-column prop="startTime" label="有效期开始时间" width="160">
          <template #default="scope">
            {{ dateFormat(scope.row.startTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="有效期结束时间" width="160">
          <template #default="scope">
            {{ dateFormat(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="membercode" label="会员码" />
        <el-table-column prop="status" label="状态" width="80">
          <template #default="scope">
            {{ statusFormat(scope.row.status) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>

  <el-dialog title="正在刷新券状态" v-model="dialogVisible" :show-close="false" :close-on-click-modal="false"
    :close-on-press-escape="false" :append-to-body="true" center width="600px">
    <el-progress :text-inside="true" :stroke-width="26" :percentage="refreshPercent"><span>{{ progressText }}</span>
    </el-progress>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import {
    getPackageDetail
  } from "@/api/package"
  import {
    formatJsonDate
  } from "@/utils/dateFormat"
  import {
    refreshVipCode
  } from "@/api/vipCode"
  import {
    createFile,
    download
  } from '../../utils/excel'

  export default {
    props: {
      packageId: {
        type: Number,
        required: true
      },
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        loading: false,
        items: [],
        refreshItems: [],
        refreshIndex: 0,
        dialogVisible: false,
        refreshPercent: 0,
        progressText: ''
      };
    },
    watch: {
      showDialog: {
        handler: function(val) {
          if (val) {
            this.init()
          }
        },
        immediate: true
      }
    },
    methods: {
      init() {
        getPackageDetail(this.packageId)
          .then((res => {
            if (res.success) {
              this.items = res.items
            }
          }))
      },
      dateFormat(time) {
        return formatJsonDate(time)
      },
      statusFormat(status) {
        switch (status) {
          case 0:
            return '未使用'
          case 1:
            return '已使用'
          case 2:
            return '已过期'
          default:
            return status
        }
      },
      handleRefresh() {
        this.refreshItems = this.items.filter(d => {
          return d.status === 0
        })

        if (this.refreshItems.length > 0) {
          this.refreshIndex = 0
          this.dialogVisible = true
          this.beginRefresh()
        }
      },
      beginRefresh() {
        if (this.refreshIndex == this.refreshItems.length) {
          this.dialogVisible = false
          this.init()
          return
        }

        this.progressText = '正在刷新 ' + (this.refreshIndex + 1) + ' / ' + this.refreshItems.length
        this.refreshPercent = this.refreshIndex / this.refreshItems.length * 100

        refreshVipCode(this.refreshItems[this.refreshIndex].code)
          .then(() => {
            if (this.dialogVisible) {
              this.refreshIndex++
              this.beginRefresh()
            }
          })
      },
      handleExport() {
        let exportData = this.items.map(d => {
          return {
            '券码': d.code,
            '有效期开始时间': this.dateFormat(d.startTime),
            '有效期结束时间': this.dateFormat(d.endTime),
            '会员码': d.membercode,
            '状态': this.statusFormat(d.status)
          }
        })

        let columnWidths = [{
          wpx: 180
        }, {
          wpx: 130
        }, {
          wpx: 130
        }, {
          wpx: 180
        }, {
          wpx: 60
        }]

        var file = createFile(exportData, columnWidths)
        download(file, '库存使用详情', document)
      },
      closeDialog() {
        this.$emit("update:showDialog", false)
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filter-box {
    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .right-box {
      float: right;
    }
  }

  .table-box {
    margin-top: 20px;
  }
</style>
