<template>
  <el-dialog :model-value="showDialog" :show-close="true" :close-on-click-modal="false" :close-on-press-escape="false"
    :append-to-body="true" center width="600px" @close="closeDialog">
    <template #title>
      <span v-if="info.ID === 0">新增库存</span>
      <span v-else>编辑库存</span>
    </template>
    <el-form ref="form" :model="info" label-position="right" label-width="100px">
      <el-form-item prop="Kind" label="券类型" :rules="{required: true, message: '券类型不能为空', trigger: 'change'}">
        <el-select v-model="item.Kind" placeholder="券类型" :disabled="info.ID > 0">
          <el-option v-for="item in kindOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="Count" label="库存数量" :rules="{required: true, message: '库存数量不能为空', trigger: 'blur'}">
        <el-input v-model="item.Count" name="Count" type="text" placeholder="库存数量" />
      </el-form-item>
      <div style="text-align:center;">
        <el-button :loading="loading" type="primary" @click="handleSave">保存</el-button>
        <el-button type="default" @click="closeDialog">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
  import {
    upsertPackage
  } from "@/api/package"

  export default {
    props: {
      info: {
        type: Object,
        required: true
      },
      refreshParent: {
        type: Function,
        required: true
      },
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        loading: false,
        kindOptions: [{
            label: '93折',
            value: 'channel_one_time_g_2107_d93'
          },
          {
            label: '88元',
            value: 'channel_one_time_g_p88'
          }
        ],
        item: {}
      };
    },
    watch: {
      showDialog: {
        handler: function(val) {
          if (val) {
            if (this.$refs.form) this.$refs.form.resetFields();
            this.item = this.info
          }
        },
        immediate: true
      }
    },
    methods: {
      handleSave() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true;
            console.log(this.info);
            upsertPackage(
                this.info.ID,
                this.info.DistributorID,
                this.info.Count,
                this.info.Kind
              )
              .then(res => {
                this.loading = false;
                if (res.success) {
                  this.$message({
                    message: "保存成功",
                    type: "success"
                  });
                  this.$emit("update:showDialog", false);
                  this.refreshParent();
                }
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
              });
          }
        });
      },
      closeDialog() {
        this.$emit("update:showDialog", false)
      }
    }
  };
</script>
