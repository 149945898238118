<template>
  <div class="container">
    <h1>库存</h1>

    <div class="box">
      <div style="width:200px; min-width:200px;">
        <el-card class="box-card">
          <template #header>
            选择分销商
          </template>
          <div v-for="d in distributor" :key="d.ID" class="item" :class="{'active':d.ID==params.DistributorId}"
            @click="handleSelect(d.ID)">
            {{ d.Name }} ({{ d.Package }})
          </div>
        </el-card>
      </div>
      <div style="overflow: hidden; flex-grow:1; padding-left:20px;">

        <div class="filter-box">
          <div class="left-box">
            {{ distributorName }} 库存详情
          </div>
          <div class="right-box">
            <el-button v-if="user_type==='1'" type="primary" size="small" icon="el-icon-plus" @click="handleNew">新增库存
            </el-button>
          </div>
        </div>

        <div class="table-box">
          <el-table :data="items" border style="width: 100%">
            <el-table-column prop="DistributorName" label="分销商名称" />
            <el-table-column prop="Kind" label="券类型" />
            <el-table-column prop="CreateTime" label="创建时间">
              <template #default="scope">
                {{ dateFormat(scope.row.CreateTime) }}
              </template>
            </el-table-column>
            <el-table-column label="库存  (已使用 / 总数)">
              <template #default="scope">
                {{ scope.row.Used }} / {{ scope.row.Count }}
                <el-button v-if="scope.row.Used>0" type="primary" size="mini" icon="el-icon-takeaway-box"
                  @click="handleDetail(scope.row.ID)">详情</el-button>
              </template>
            </el-table-column>
            <el-table-column v-if="user_type==='1'" label="操作" width="150">
              <template #default="scope">
                <el-button type="primary" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div style="margin:20px auto; text-align:center">
            <el-pagination :page-size="params.Take" :total="totalCount" background layout="total, prev, pager, next"
              @current-change="handlePageChange" />
          </div>

        </div>
      </div>
    </div>

    <edit :info="curItem" v-model:showDialog="showEdit" :refresh-parent="initDistributor" />
    <detail :packageId="curPackageId" v-model:showDialog="showDetail" />
  </div>
</template>

<script>
  import {
    getAllDistributorListWithPackage
  } from "@/api/distributor"
  import {
    getPackageList
  } from "@/api/package"
  import {
    formatJsonDate
  } from "../../utils/dateFormat"

  import Edit from "./packageEdit"
  import Detail from "./packageDetail"

  export default {
    components: {
      Edit,
      Detail
    },
    data() {
      return {
        loading: false,
        params: {
          DistributorId: null,
          Skip: 0,
          Take: 20
        },
        distributor: [],
        items: [],
        totalCount: 0,

        curItem: {},
        showEdit: false,
        curPackageId: 0,
        showDetail: false,
      }
    },
    computed: {
      user_type() {
        return this.$store.getters.user_type;
      },
      distributorName() {
        let that = this
        var f = that.distributor.filter(d => {
          return d.ID == that.params.DistributorId
        })

        if (this.distributor.length === 0)
          return ''
        else
          return f[0].Name
      }
    },
    mounted() {
      this.initDistributor()
    },
    methods: {
      initDistributor() {
        getAllDistributorListWithPackage()
          .then(res => {
            if (res.success) {
              this.distributor = res.items

              if (this.params.DistributorId == null)
                this.params.DistributorId = this.distributor[0].ID

              this.init()
            }
          })
      },
      init() {
        getPackageList(this.params.DistributorId, this.params.Skip, this.params.Take)
          .then(res => {
            if (res.success) {
              this.items = res.items
              this.totalCount = res.totalCount
            }
          })
      },
      dateFormat(time) {
        return formatJsonDate(time)
      },
      handleSelect(id) {
        this.params.Skip = 0
        this.params.DistributorId = id
        this.init()
      },
      handlePageChange(page) {
        this.params.Skip = (page - 1) * this.params.Take
        this.init()
      },
      handleNew() {
        this.curItem = {
          ID: 0,
          DistributorID: this.params.DistributorId,
          Count: '',
          Kind: 'channel_one_time_g_2107_d93'
        }
        this.showEdit = true
      },
      handleEdit(row) {
        this.curItem = {
          ID: row.ID,
          DistributorID: this.params.DistributorId,
          Count: row.Count,
          Kind: row.Kind
        }
        this.showEdit = true
      },
      handleDetail(id) {
        this.curPackageId = id
        this.showDetail = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .item {
      height: 35px;
      line-height: 35px;
      cursor: pointer;
      color: #888;
    }

    .active {
      color: #000;
    }

    .box {
      display: flex;
      flex-direction: row;
    }

    .filter-box {
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;
        line-height: 32px;
        font-weight: bold;
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;
    }
  }
</style>
